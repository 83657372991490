*,
::before,
::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia:
}

*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}

::-webkit-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia:
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia:
}

.c8e87 {
  width: 100%
}

@media (min-width: 640px) {
  .c8e87 {
    max-width: 640px
  }
}

@media (min-width: 768px) {
  .c8e87 {
    max-width: 768px
  }
}

@media (min-width: 1024px) {
  .c8e87 {
    max-width: 1024px
  }
}

@media (min-width: 1280px) {
  .c8e87 {
    max-width: 1280px
  }
}

@media (min-width: 1536px) {
  .c8e87 {
    max-width: 1536px
  }
}

.caeqc {
  font-size: 2.75rem;
  line-height: 1;
  letter-spacing: -0.01em;
  font-weight: 700
}

.cvg4m {
  font-size: 2.25rem;
  line-height: 1.1;
  letter-spacing: -0.01em;
  font-weight: 700
}

.cmvn8 {
  font-size: 1.875rem;
  line-height: 1.333;
  letter-spacing: -0.01em;
  font-weight: 700
}

.cj3nc {
  font-size: 1.5rem;
  line-height: 1.415;
  letter-spacing: -0.01em;
  font-weight: 700
}

@media (min-width: 768px) {
  .caeqc {
    font-size: 3.25rem;
    line-height: 1;
    letter-spacing: -0.01em
  }

  .cvg4m {
    font-size: 2.75rem;
    line-height: 1;
    letter-spacing: -0.01em
  }

  .cmvn8 {
    font-size: 2.25rem;
    line-height: 1.1;
    letter-spacing: -0.01em
  }
}

.cclx0,
.cy2kq {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border-radius: 9999px;
  font-size: 15px;
  font-weight: 550;
  line-height: 1.25rem;
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

.cclx0 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem
}

.cy2kq {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none
}

.c67pd,
.cgctd,
.cwevg,
.cq80v,
.cs7cv,
.crr2y {
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240/var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255/var(--tw-bg-opacity))
}

.c67pd:focus,
.cgctd:focus,
.cwevg:focus,
.cq80v:focus,
.cs7cv:focus,
.crr2y:focus {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246/var(--tw-border-opacity))
}

.c67pd,
.cgctd,
.cwevg,
.cq80v,
.cs7cv {
  border-radius: 0.25rem
}

.c67pd,
.cgctd,
.cwevg,
.cq80v {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5715
}

.c67pd::-moz-placeholder,
.cgctd::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(148 163 184/var(--tw-placeholder-opacity))
}

.c67pd:-ms-input-placeholder,
.cgctd:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(148 163 184/var(--tw-placeholder-opacity))
}

.c67pd::placeholder,
.cgctd::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(148 163 184/var(--tw-placeholder-opacity))
}

.cq80v {
  padding-right: 2.5rem
}

.cs7cv,
.crr2y {
  border-radius: 0.125rem;
  border-width: 2px;
  --tw-text-opacity: 1;
  color: rgb(59 130 246/var(--tw-text-opacity))
}

.chpss::-webkit-scrollbar {
  display: none
}

.chpss {
  -ms-overflow-style: none;
  scrollbar-width: none
}

:root {
  --range-thumb-size: 36px
}

input[type=range] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #ccc;
  border-radius: 3px;
  height: 6px;
  margin-top: (--range-thumb-size - 6px)*0.5;
  margin-bottom: (--range-thumb-size - 6px)*0.5;

  --thumb-size: # {
    --range-thumb-size
  }
}

input[type=range]::-webkit-slider-thumb {
  appearance: none;
  -webkit-appearance: none;
  background-color: #000;
  background-image: url(data:image/svg+xml,%3Csvg\ width=\'12\'\ height=\'8\'\ xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath\ d=\'M8\ .5v7L12\ 4zM0\ 4l4\ 3.5v-7z\'\ fill=\'%23FFF\'\ fill-rule=\'nonzero\'/%3E%3C/svg%3E);
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  height: --range-thumb-size;
  width: --range-thumb-size
}

input[type=range]::-moz-range-thumb {
  background-color: #000;
  background-image: url(data:image/svg+xml,%3Csvg\ width=\'12\'\ height=\'8\'\ xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath\ d=\'M8\ .5v7L12\ 4zM0\ 4l4\ 3.5v-7z\'\ fill=\'%23FFF\'\ fill-rule=\'nonzero\'/%3E%3C/svg%3E);
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: --range-thumb-size;
  width: --range-thumb-size
}

input[type=range]::-ms-thumb {
  background-color: #000;
  background-image: url(data:image/svg+xml,%3Csvg\ width=\'12\'\ height=\'8\'\ xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath\ d=\'M8\ .5v7L12\ 4zM0\ 4l4\ 3.5v-7z\'\ fill=\'%23FFF\'\ fill-rule=\'nonzero\'/%3E%3C/svg%3E);
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  height: --range-thumb-size;
  width: --range-thumb-size
}

input[type=range]::-moz-focus-outer {
  border: 0
}

.cq471 {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 44px
}

.cq471 label {
  display: block;
  height: 1.5rem;
  cursor: pointer;
  overflow: hidden;
  border-radius: 9999px
}

.cq471 label>span:first-child {
  position: absolute;
  display: block;
  border-radius: 9999px;
  width: 20px;
  height: 20px;
  top: 2px;
  left: 2px;
  right: 50%;
  transition: all .15s ease-out
}

.cq471 input[type="checkbox"]:checked+label {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246/var(--tw-bg-opacity))
}

.cq471 input[type="checkbox"]:checked+label>span:first-child {
  left: 22px
}

.cq471 input[type="checkbox"]:disabled+label {
  cursor: not-allowed;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240/var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249/var(--tw-bg-opacity))
}

.cq471 input[type="checkbox"]:disabled+label>span:first-child {
  --tw-bg-opacity: 1;
  background-color: rgb(148 163 184/var(--tw-bg-opacity))
}

.c67pd:focus:focus,
.cgctd:focus:focus,
.cwevg:focus:focus,
.cq80v:focus:focus,
.cs7cv:focus:focus,
.crr2y:focus:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(191 219 254 / var(--tw-ring-opacity))
}

.cs7cv:focus:focus,
.crr2y:focus:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.cts17 svg>*:nth-child(1),
.cts17 svg>*:nth-child(2),
.cts17 svg>*:nth-child(3) {
  transform-origin: center;
  transform: rotate(0deg)
}

.cts17 svg>*:nth-child(1) {
  transition: y 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), opacity 0.1s ease-in
}

.cts17 svg>*:nth-child(2) {
  transition: transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19)
}

.cts17 svg>*:nth-child(3) {
  transition: y 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), width 0.1s 0.25s ease-in
}

.cts17.cm5vz svg>*:nth-child(1) {
  opacity: 0;
  y: 11;
  transform: rotate(225deg);
  transition: y 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.1s 0.12s ease-out
}

.cts17.cm5vz svg>*:nth-child(2) {
  transform: rotate(225deg);
  transition: transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1)
}

.cts17.cm5vz svg>*:nth-child(3) {
  y: 11;
  transform: rotate(135deg);
  transition: y 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), width 0.1s ease-out
}

@media screen {
  html:not(.cyxym) [data-aos=fade-up] {
    transform: translate3d(0, 16px, 0)
  }

  html:not(.cyxym) [data-aos=fade-down] {
    transform: translate3d(0, -16px, 0)
  }

  html:not(.cyxym) [data-aos=fade-right] {
    transform: translate3d(-16px, 0, 0)
  }

  html:not(.cyxym) [data-aos=fade-left] {
    transform: translate3d(16px, 0, 0)
  }

  html:not(.cyxym) [data-aos=fade-up-right] {
    transform: translate3d(-16px, 16px, 0)
  }

  html:not(.cyxym) [data-aos=fade-up-left] {
    transform: translate3d(16px, 16px, 0)
  }

  html:not(.cyxym) [data-aos=fade-down-right] {
    transform: translate3d(-16px, -16px, 0)
  }

  html:not(.cyxym) [data-aos=fade-down-left] {
    transform: translate3d(16px, -16px, 0)
  }

  html:not(.cyxym) [data-aos=zoom-in-up] {
    transform: translate3d(0, 16px, 0) scale(.6)
  }

  html:not(.cyxym) [data-aos=zoom-in-down] {
    transform: translate3d(0, -16px, 0) scale(.6)
  }

  html:not(.cyxym) [data-aos=zoom-in-right] {
    transform: translate3d(-16px, 0, 0) scale(.6)
  }

  html:not(.cyxym) [data-aos=zoom-in-left] {
    transform: translate3d(16px, 0, 0) scale(.6)
  }

  html:not(.cyxym) [data-aos=zoom-out-up] {
    transform: translate3d(0, 16px, 0) scale(1.2)
  }

  html:not(.cyxym) [data-aos=zoom-out-down] {
    transform: translate3d(0, -16px, 0) scale(1.2)
  }

  html:not(.cyxym) [data-aos=zoom-out-right] {
    transform: translate3d(-16px, 0, 0) scale(1.2)
  }

  html:not(.cyxym) [data-aos=zoom-out-left] {
    transform: translate3d(16px, 0, 0) scale(1.2)
  }
}

.swiper-pagination-bullet {
  --tw-bg-opacity: 1;
  background-color: rgb(203 213 225/var(--tw-bg-opacity));
  opacity: 1
}

.swiper-pagination-bullet-active {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246/var(--tw-bg-opacity))
}

.cobi9 {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0
}

.cqwz3 {
  pointer-events: none
}

.c21p0 {
  visibility: visible
}

.crntr {
  position: static
}

.ccmr6 {
  position: fixed
}

.cw4j8 {
  position: absolute
}

.ciev1 {
  position: relative
}

.c2if9 {
  position: -webkit-sticky;
  position: sticky
}

.c0thn {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px
}

.c6mh0 {
  top: 50%
}

.cgw3k {
  left: 50%
}

.cat50 {
  bottom: 100%
}

.csp47 {
  top: 0px
}

.c765r {
  right: 0px
}

.c14hc {
  bottom: 0px
}

.cumtw {
  left: 0px
}

.cuvu3 {
  z-index: 30
}

.cb0o6 {
  z-index: -10
}

.c4nrc {
  z-index: 10
}

.c42gm {
  margin: -1rem
}

.cuovc {
  margin: 0px
}

.c8afs {
  margin-left: auto;
  margin-right: auto
}

.crqgo {
  margin-left: -1rem;
  margin-right: -1rem
}

.cx23e {
  margin-left: -4rem;
  margin-right: -4rem
}

.chzze {
  margin-left: -2rem;
  margin-right: -2rem
}

/* .cm1wc {
  margin-left: -3.5rem;
  margin-right: -3.5rem
} */

.c7y8z {
  margin-left: 0.5rem;
  margin-right: 0.5rem
}

.c31o2 {
  margin-right: 1rem
}

.c4lox {
  margin-bottom: 4rem
}

.cz0di {
  margin-bottom: 2rem
}

.csrf8 {
  margin-bottom: 3rem
}

.cucat {
  margin-right: 0.75rem
}

.ccvec {
  margin-bottom: 1.25rem
}

.cmc1v {
  margin-bottom: 11rem
}

.c9ggb {
  margin-top: -10rem
}

.c4gqd {
  margin-left: 5rem
}

.c553w {
  margin-top: 0.625rem
}

.ccg7h {
  margin-bottom: 0.375rem
}

.cdxc0 {
  margin-top: 0.5rem
}

.cct3x {
  margin-bottom: 0.25rem
}

.cw7wm {
  margin-top: 1.5rem
}

.cnc65 {
  margin-left: 0.75rem
}

.c2x1p {
  margin-left: 0.5rem
}

.ckl6d {
  margin-bottom: 7rem
}

.crkpn {
  margin-bottom: 1.5rem
}

.c5f8z {
  margin-bottom: -3rem
}

.ce3dt {
  margin-left: -0.75rem
}

.cy2rp {
  margin-right: -6rem
}

.c0hke {
  margin-top: 4rem
}

.cuowh {
  margin-bottom: 0.75rem
}

.ctkul {
  margin-bottom: 6rem
}

.czebt {
  margin-bottom: 1rem
}

.cl4i1 {
  margin-top: 2rem
}

.cdjne {
  margin-top: 3rem
}

.cnq6c {
  margin-left: auto
}

.c3q14 {
  margin-bottom: 2.5rem
}

.crj9e {
  margin-top: -3.5rem
}

.cwpmn {
  margin-top: 1rem
}

.ch8cw {
  margin-top: 0.375rem
}

.cndea {
  margin-top: 0.25rem
}

.cy5kf {
  margin-right: 1.5rem
}

.ckwvk {
  margin-left: 1rem
}

.cdzzn {
  margin-right: 0.5rem
}

.cgkbh {
  margin-left: 0.625rem
}

.ci7xo {
  margin-top: 0px
}

.cdmxb {
  display: block
}

.cl722 {
  display: inline
}

.c90zz {
  display: flex
}

.cuscs {
  display: inline-flex
}

.couxe {
  display: table
}

.c1ymh {
  display: grid
}

.hidden {
  display: none
}

.ckws0 {
  height: 4rem
}

.cvl45 {
  height: 2.75rem
}

.cszdf {
  height: auto
}

.c1gk0 {
  height: 100%
}

.cx4go {
  height: 0.75rem
}

.c8emb {
  height: 100vh
}

.cyqa8 {
  height: 1.5rem
}

.cf4tn {
  height: 6rem
}

.c0869 {
  min-height: 100vh
}

.cn7sg {
  width: 100%
}

.cn4kh {
  width: 512px
}

.czov6 {
  width: 2.75rem
}

.c75uz {
  width: 0.75rem
}

.cu167 {
  width: 16rem
}

.c3jnt {
  width: 1.5rem
}

.cgcpb {
  min-width: 0px
}

.cq3ss {
  max-width: 72rem
}

.cwddw {
  max-width: 100%
}

.coe5y {
  max-width: none
}

.c26rt {
  max-width: 480px
}

.c9me5 {
  max-width: 36rem
}

.c32fv {
  max-width: 20rem
}

.cmzle {
  max-width: 24rem
}

.c0s0y {
  max-width: 80rem
}

.cpf2o {
  max-width: 28rem
}

.c45v2 {
  max-width: 56rem
}

.cbjk5 {
  flex-shrink: 0
}

.cclc4 {
  flex-grow: 1
}

.cgibt {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.c9w4z {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.cnvwf {
  --tw-translate-y: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.c45sp {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.c9i72 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.cc2gh {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.ck3sy {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.cqrok {
  resize: both
}

.c6i6j {
  grid-template-columns: repeat(1, minmax(0, 1fr))
}

.c8ykw {
  grid-template-columns: repeat(2, minmax(0, 1fr))
}

.cj7ct {
  flex-direction: column
}

.ccku4 {
  flex-wrap: wrap
}

.cbl50 {
  flex-wrap: nowrap
}

.c1nxv {
  align-items: flex-start
}

.cbsyx {
  align-items: center
}

.c9n9z {
  justify-content: flex-end
}

.cgjfv {
  justify-content: center
}

.cueat {
  justify-content: space-between
}

.cked8 {
  gap: 3rem
}

.c5py1 {
  gap: 2rem
}

.cgwxx {
  gap: 1.5rem
}

.caipo>:not([hidden])~:not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.625rem*calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.625rem*var(--tw-space-y-reverse))
}

.cfihk>:not([hidden])~:not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem*calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem*var(--tw-space-y-reverse))
}

.clnjj>:not([hidden])~:not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem*calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem*var(--tw-space-y-reverse))
}

.civgx>:not([hidden])~:not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem*calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem*var(--tw-space-y-reverse))
}

.c9byq>:not([hidden])~:not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem*calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem*var(--tw-space-y-reverse))
}

.c8doo>:not([hidden])~:not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem*calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem*var(--tw-space-y-reverse))
}

.c6qta>:not([hidden])~:not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem*var(--tw-space-x-reverse));
  margin-left: calc(1.5rem*calc(1 - var(--tw-space-x-reverse)))
}

.cl1i4>:not([hidden])~:not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem*var(--tw-space-x-reverse));
  margin-left: calc(0.5rem*calc(1 - var(--tw-space-x-reverse)))
}

.cedvy>:not([hidden])~:not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem*var(--tw-space-x-reverse));
  margin-left: calc(1rem*calc(1 - var(--tw-space-x-reverse)))
}

.cjdu8>:not([hidden])~:not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px*calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px*var(--tw-divide-y-reverse))
}

.cxnym {
  align-self: flex-start
}

.cn4e8 {
  overflow: hidden
}

.cpfa2 {
  overflow-y: auto
}

.cghid {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.cn497 {
  white-space: nowrap
}

.csyf7 {
  border-radius: 9999px
}

.ccryv {
  border-radius: 0.5rem
}

.ckxwx {
  border-radius: 0.375rem
}

.c44uc {
  border-radius: 0.25rem
}

.c1u9j {
  border-bottom-left-radius: 100px
}

.czijl {
  border-top-right-radius: 100px
}

.c6ig5 {
  border-top-left-radius: 100px
}

.cl239 {
  border-bottom-right-radius: 100px
}

.cdl4r {
  border-width: 2px
}

.caivo {
  border-width: 0px
}

.c6fxk {
  border-top-width: 1px
}

.c58ub {
  border-bottom-width: 1px
}

.cb1oe {
  border-right-width: 1px
}

.cwn54 {
  border-bottom-width: 2px
}

.cliib {
  --tw-border-opacity: 1;
  border-color: rgb(241 245 249/var(--tw-border-opacity))
}

.c9udw {
  --tw-border-opacity: 1;
  border-color: rgb(51 65 85/var(--tw-border-opacity))
}

.cferi {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240/var(--tw-border-opacity))
}

.csz2x {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219/var(--tw-border-opacity))
}

.cnpgr {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235/var(--tw-border-opacity))
}

.cjiu5 {
  border-color: transparent
}

.cibfk {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255/var(--tw-bg-opacity))
}

.ciduz {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246/var(--tw-bg-opacity))
}

.ckf1f {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59/var(--tw-bg-opacity))
}

.cw762 {
  --tw-bg-opacity: 1;
  background-color: rgb(153 246 228/var(--tw-bg-opacity))
}

.c8n05 {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249/var(--tw-bg-opacity))
}

.c272x {
  --tw-bg-opacity: 1;
  background-color: rgb(20 184 166/var(--tw-bg-opacity))
}

.c7dey {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85/var(--tw-bg-opacity))
}

.cdk1q {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246/var(--tw-bg-opacity))
}

.cygke {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235/var(--tw-bg-opacity))
}

.ci4ty {
  --tw-bg-opacity: 0.75
}

.c8sta {
  background-image: linear-gradient(to top right, var(--tw-gradient-stops))
}

.cog9c {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops))
}

.cppns {
  background-image: linear-gradient(to top, var(--tw-gradient-stops))
}

.cxcdb {
  --tw-gradient-from: #2563eb;
  --tw-gradient-to: rgb(37 99 235 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.c7uew {
  --tw-gradient-from: #60a5fa;
  --tw-gradient-to: rgb(96 165 250 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.cpjmk {
  --tw-gradient-from: #f1f5f9;
  --tw-gradient-to: rgb(241 245 249 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.cx1fe {
  --tw-gradient-from: #fff;
  --tw-gradient-to: rgb(255 255 255 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.c5m09 {
  --tw-gradient-from: #1e293b;
  --tw-gradient-to: rgb(30 41 59 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.c1rl0 {
  --tw-gradient-from: #dbeafe;
  --tw-gradient-to: rgb(219 234 254 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.chitq {
  --tw-gradient-to: #3b82f6
}

.cy3ez {
  --tw-gradient-to: #334155
}

.couzf {
  --tw-gradient-to: #eff6ff
}

.cxams {
  fill: #3b82f6
}

.c0glf {
  fill: #7dd3fc
}

.cwobz {
  fill: #dbeafe
}

.cl6pl {
  fill: #eff6ff
}

.cxx8b {
  fill: currentColor
}

.cu6b3 {
  fill: #94a3b8
}

.ch9dq {
  fill: #475569
}

.ch2nd {
  fill: #93c5fd
}

.czamc {
  padding: 1rem
}

.cecyf {
  padding: 1.5rem
}

.cbbno {
  padding: 0.25rem
}

.c795p {
  padding-left: 1rem;
  padding-right: 1rem
}

.c1pgd {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.ciwss {
  padding-top: 3rem;
  padding-bottom: 3rem
}

.c6d4u {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem
}

.clzpq {
  padding-left: 1.5rem;
  padding-right: 1.5rem
}

.c11w6 {
  padding-left: 0.75rem;
  padding-right: 0.75rem
}

.cqhcy {
  padding-top: 1px;
  padding-bottom: 1px
}

.cywql {
  padding-top: 2rem;
  padding-bottom: 2rem
}

.caocc {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem
}

.cr4g0 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem
}

.ch7ks {
  padding-left: 0.125rem;
  padding-right: 0.125rem
}

.cuvxf {
  padding-left: 0px;
  padding-right: 0px
}

.ctnep {
  padding-top: 9rem
}

.clnn3 {
  padding-bottom: 3rem
}

.cqi9v {
  padding-top: 3rem
}

.c96cp {
  padding-bottom: 1.5rem
}

.cz3eq {
  padding-top: 3.5rem
}

.cxkvn {
  padding-bottom: 1rem
}

.c1eci {
  padding-top: 7rem
}

.cnguu {
  padding-left: 1.5rem
}

.ckn2c {
  padding-top: 2rem
}

.c6ekv {
  text-align: left
}

.ch13c {
  text-align: center
}

.ctx4y {
  text-align: right
}

.ckgr9 {
  font-family: Aspekta, sans-serif
}

.c4gs1 {
  font-size: 0.75rem;
  line-height: 1.5
}

.cflrl {
  font-size: 0.875rem;
  line-height: 1.5715
}

.c1yzh {
  font-size: 1.125rem;
  line-height: 1.5;
  letter-spacing: -0.01em
}

.cij91 {
  font-size: 1.25rem;
  line-height: 1.5;
  letter-spacing: -0.01em
}

.czjx8 {
  font-size: 1.5rem;
  line-height: 1.415;
  letter-spacing: -0.01em
}

.cquci {
  font-size: 2.25rem;
  line-height: 1.1;
  letter-spacing: -0.01em
}

.c632t {
  font-weight: 350
}

.ck0rf {
  font-weight: 550
}

.cuj67 {
  font-weight: 700
}

.cuy1u {
  font-weight: 500
}

.c20ei {
  font-weight: 450
}

.c6zz0 {
  text-transform: uppercase
}

.c5o8q {
  line-height: 1.25rem
}

.c1dh0 {
  letter-spacing: 0.02em
}

.c16sn {
  letter-spacing: 0
}

.c667e {
  --tw-text-opacity: 1;
  color: rgb(30 41 59/var(--tw-text-opacity));
}

.crs7v {
  --tw-text-opacity: 1;
  color: rgb(100 116 139/var(--tw-text-opacity))
}

.c3bg1 {
  --tw-text-opacity: 1;
  color: rgb(148 163 184/var(--tw-text-opacity))
}

.cwvj9 {
  --tw-text-opacity: 1;
  color: rgb(239 246 255/var(--tw-text-opacity))
}

.camqk {
  --tw-text-opacity: 1;
  color: rgb(241 245 249/var(--tw-text-opacity))
}

.cx4n8 {
  --tw-text-opacity: 1;
  color: rgb(56 189 248/var(--tw-text-opacity))
}

.cfni9 {
  --tw-text-opacity: 1;
  color: rgb(255 255 255/var(--tw-text-opacity))
}

.cw46s {
  --tw-text-opacity: 1;
  color: rgb(191 219 254/var(--tw-text-opacity))
}

.cdymx {
  --tw-text-opacity: 1;
  color: rgb(125 211 252/var(--tw-text-opacity))
}

.cio7a {
  --tw-text-opacity: 1;
  color: rgb(13 148 136/var(--tw-text-opacity))
}

.cwqik {
  --tw-text-opacity: 1;
  color: rgb(226 232 240/var(--tw-text-opacity))
}

.cwh4b {
  --tw-text-opacity: 1;
  color: rgb(20 184 166/var(--tw-text-opacity))
}

.cta4p {
  --tw-text-opacity: 1;
  color: rgb(59 130 246/var(--tw-text-opacity))
}

.cy2l2 {
  --tw-text-opacity: 1;
  color: rgb(71 85 105/var(--tw-text-opacity))
}

.cxnjm {
  --tw-text-opacity: 1;
  color: rgb(17 24 39/var(--tw-text-opacity))
}

.cr4kq {
  --tw-text-opacity: 1;
  color: rgb(75 85 99/var(--tw-text-opacity))
}

.culi9 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81/var(--tw-text-opacity))
}

.c0on0 {
  --tw-text-opacity: 1;
  color: rgb(79 70 229/var(--tw-text-opacity))
}

.cw2sv {
  --tw-text-opacity: 1;
  color: rgb(0 0 0/var(--tw-text-opacity))
}

.c8bcy {
  --tw-text-opacity: 1;
  color: rgb(107 114 128/var(--tw-text-opacity))
}

.cij9j {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline
}

.cskw3 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.c880b {
  opacity: 0
}

.c1m85 {
  opacity: 1
}

.c6n6n {
  mix-blend-mode: lighten
}

.c99rw {
  --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.czb8d {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.ce3jr {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.ckght {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.ceuwi {
  --tw-invert: invert(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.c36yx {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.cpy9x {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.cpmmw {
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.cd2vy {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ctlbf {
  transition-duration: 150ms
}

.cm5b2 {
  transition-duration: 200ms
}

.ceqzy {
  transition-duration: 100ms
}

.ci96u {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

.cdtmc {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
}

.cre2k {
  direction: rtl
}

[x-cloak=""] {
  display: none
}

.czzy2::before {
  content: var(--tw-content);
  position: absolute
}

.clvx9::before {
  content: var(--tw-content);
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px
}

.c01rt::before {
  content: var(--tw-content);
  z-index: -10
}

.clqa1::before {
  content: var(--tw-content);
  border-radius: 9999px
}

.cj6s7::before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(96 165 250/var(--tw-bg-opacity))
}

.cdwim::before {
  content: var(--tw-content);
  --tw-bg-opacity: 0.6
}

.ccfr6::after {
  content: var(--tw-content);
  position: absolute
}

.cskbp::after {
  content: var(--tw-content);
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px
}

.caf9c::after {
  content: var(--tw-content);
  right: 0px
}

.cxrjr::after {
  content: var(--tw-content);
  top: 50%
}

.cjbbj::after {
  content: var(--tw-content);
  display: none
}

.cmv89::after {
  content: var(--tw-content);
  height: 4rem
}

.c7l6m::after {
  content: var(--tw-content);
  width: 1px
}

.chha8::after {
  content: var(--tw-content);
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.cpw35::after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240/var(--tw-bg-opacity))
}

.cfuru::after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(96 165 250/var(--tw-bg-opacity))
}

.ctm2i::after {
  content: var(--tw-content);
  background-image: linear-gradient(to top, var(--tw-gradient-stops))
}

.cb6xh::after {
  content: var(--tw-content);
  --tw-gradient-from: #334155;
  --tw-gradient-to: rgb(51 65 85 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.cpkd2:last-child::after {
  content: var(--tw-content);
  display: none
}

.ckjmd:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235/var(--tw-bg-opacity))
}

.cpygj:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42/var(--tw-bg-opacity))
}

.c06pf:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246/var(--tw-bg-opacity))
}

.cevcb:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59/var(--tw-bg-opacity))
}

.cwaev:hover {
  --tw-text-opacity: 1;
  color: rgb(59 130 246/var(--tw-text-opacity))
}

.cbzo9:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline
}

.cyy8w:focus {
  --tw-border-opacity: 1;
  border-color: rgb(165 180 252/var(--tw-border-opacity))
}

.c9z2m:focus {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0/var(--tw-border-opacity))
}

.c48zm:focus {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219/var(--tw-border-opacity))
}

.c5919:focus {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128/var(--tw-border-opacity))
}

.cx7c0:focus {
  border-color: transparent
}

.chwb2:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255/var(--tw-bg-opacity))
}

.cukzd:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235/var(--tw-bg-opacity))
}

.c2fsz:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.c8s6v:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.csrsd:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.c5bp0:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(199 210 254 / var(--tw-ring-opacity))
}

.cgcpx:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity))
}

.c670l:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(107 114 128 / var(--tw-ring-opacity))
}

.cpmpt:focus {
  --tw-ring-opacity: 0.5
}

.cfpbx:focus {
  --tw-ring-offset-width: 0px
}

.c7zus:focus {
  --tw-ring-offset-width: 2px
}

.cww28:hover .cm4hz {
  --tw-translate-x: 0.125rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.cww28:hover .cni6q {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

@media (min-width: 640px) {
  .cfhfj {
    grid-column: span 12/span 12
  }

  .c8866 {
    grid-column: span 6/span 6
  }

  .c2x6m {
    margin-left: -1.5rem;
    margin-right: -1.5rem
  }

  .csh1i {
    margin-bottom: 0px
  }

  .ctov5 {
    display: flex
  }

  .cfvwy {
    width: 18rem
  }

  .cvq63 {
    max-width: none
  }

  .cspnv {
    -moz-columns: 2;
    columns: 2
  }

  .ckfpy {
    grid-template-columns: repeat(12, minmax(0, 1fr))
  }

  .c8mjt {
    flex-direction: column
  }

  .cv4g6 {
    justify-content: center
  }

  .c0dlp>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem*var(--tw-space-x-reverse));
    margin-left: calc(1rem*calc(1 - var(--tw-space-x-reverse)))
  }

  .c2ayx>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px*calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px*var(--tw-space-y-reverse))
  }

  .cybvu {
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }
}

@media (min-width: 768px) {
  .cwkzg {
    position: absolute
  }

  .c47jn {
    left: 600px
  }

  .czmsw {
    top: 0px
  }

  .chf79 {
    left: 412px
  }

  .c7qzx {
    left: auto
  }

  .c4vz6 {
    order: 1
  }

  .cqkqb {
    grid-column: span 3/span 3
  }

  .cz21g {
    margin-left: 0px;
    margin-right: 0px
  }

  .cdz4n {
    margin-left: -2.25rem;
    margin-right: -2.25rem
  }

  .crsdq {
    margin-bottom: 0px
  }

  .cxxu5 {
    margin-top: -3rem
  }

  .cs6or {
    margin-top: 0px
  }

  .c42d3 {
    margin-top: 9rem
  }

  .cntah {
    margin-top: 5rem
  }

  .cski4 {
    margin-right: 2rem
  }

  .chevy {
    margin-left: 2rem
  }

  .cie5d {
    margin-bottom: 5rem
  }

  .cbmh8 {
    display: block
  }

  .cb089 {
    display: block !important
  }

  .cmww6 {
    display: flex
  }

  .cea7d {
    display: none
  }

  .cszhs {
    height: 5rem
  }

  .c7vr0 {
    width: 600px
  }

  .ch223 {
    width: 50%
  }

  .cvznp {
    max-width: none
  }

  .c34yk {
    max-width: 32rem
  }

  .czied {
    max-width: 48rem
  }

  .cysxe {
    max-width: 42rem
  }

  .ch8o4 {
    max-width: 56rem
  }

  .cp383 {
    flex-shrink: 0
  }

  .c5dmg {
    flex-grow: 1
  }

  .cbmcv {
    --tw-rotate: 48deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  .cle81 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .cji5s {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .cmkcp {
    flex-direction: row
  }

  .cay85 {
    justify-content: flex-start
  }

  .c5flp {
    justify-content: space-between
  }

  .ch8y8 {
    gap: 0px
  }

  .c0e9u>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem*var(--tw-space-x-reverse));
    margin-left: calc(3rem*calc(1 - var(--tw-space-x-reverse)))
  }

  .cw6n0>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px*calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px*var(--tw-space-y-reverse))
  }

  .ctesf {
    padding-top: 7rem;
    padding-bottom: 7rem
  }

  .c0jxq {
    padding-left: 2.25rem;
    padding-right: 2.25rem
  }

  .caplu {
    padding-top: 5rem;
    padding-bottom: 5rem
  }

  .c2i66 {
    padding-top: 10rem
  }

  .ckf1e {
    padding-bottom: 5rem
  }

  .cap18 {
    padding-top: 5rem
  }

  .cts81 {
    padding-bottom: 2rem
  }

  .cfjrc {
    padding-right: 2rem
  }

  .c6ny9 {
    padding-top: 9rem
  }

  .c2ago {
    padding-bottom: 4rem
  }

  .cvwkl {
    padding-left: 21rem
  }

  .cj9l2 {
    text-align: left
  }

  .c2pcv {
    opacity: 1 !important
  }

  .cffde::after {
    content: var(--tw-content);
    display: block
  }
}

@media (min-width: 1024px) {
  .c5j1h {
    grid-column: span 2/span 2
  }

  .c6qff {
    margin: -0.5rem
  }

  .cpot0 {
    margin-left: 0px;
    margin-right: 0px
  }

  .cbrjz {
    margin-top: 4rem
  }

  .ce36l {
    margin-bottom: 0px
  }

  .coo2t {
    margin-top: -9999px
  }

  .czrjx {
    margin-left: 0px
  }

  .clqx1 {
    margin-right: -9999px
  }

  .csspv {
    margin-right: 4rem
  }

  .cocng {
    display: flex
  }

  .chd1v {
    width: 480px
  }

  .cil68 {
    max-width: none
  }

  .c7zk6 {
    max-width: 20rem
  }

  .csdae {
    grid-template-columns: repeat(10, minmax(0, 1fr))
  }

  .c7dj5 {
    flex-direction: row
  }

  .c25ty {
    justify-content: flex-start
  }

  .cerhi>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem*var(--tw-space-x-reverse));
    margin-left: calc(3rem*calc(1 - var(--tw-space-x-reverse)))
  }

  .cz6g9 {
    padding: 0.5rem
  }

  .cb6ax {
    padding-top: 0px
  }

  .cdvjj {
    padding-left: 3rem
  }

  .ci03a {
    text-align: left
  }
}

@media (min-width: 1280px) {
  .c1643 {
    margin: -1rem
  }

  .c6cfh {
    width: 512px
  }

  .c3x11 {
    width: 20rem
  }

  .c5xjq {
    max-width: none
  }

  .cry79 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .cgzxz {
    gap: 1.5rem;
  }

  .cwvn0>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem*var(--tw-space-x-reverse));
    margin-left: calc(4rem*calc(1 - var(--tw-space-x-reverse)))
  }

  .clpev {
    padding: 1rem
  }

  .crxp1 {
    padding-left: 5rem
  }
}

.dark .is-divider {
  background-color: aliceblue;
}

.widget .is-divider {
  margin-top: 0.66em;
}

.is-divider {
  background-color: #3b82f6;
  display: block;
  height: 3px;
  margin: 1em 0;
  max-width: 30px;
  width: 100%;
}

.front,
.dgcard .back {
  display: block;
  height: 100%;
  width: 40%;
  line-height: 260px;
  color: #fff;
  text-align: center;
  font-size: 4em;
  position: absolute;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 8px;
  box-shadow: none;
}

.dgcard {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  height: 40vh;
  align-items: center;
}

.gradient-text {
  font-family: archivo black, sans-serif;
  font-weight: 400;
  font-size: 30px;
  text-align: left;
  margin-bottom: 0;
  margin-bottom: -0.25em;
  background-color: transparent;
  position: absolute;
  bottom: 40px;
  left: 40px;
  line-height: 30px;
  background-size: 100%;
  background-repeat: repeat;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gold {
  background: -webkit-linear-gradient(right bottom, rgb(247, 213, 126) 0%, rgb(231, 200, 83) 100%);
}

.silver {
  background: -webkit-linear-gradient(right bottom, rgb(255, 255, 255) 0%, rgb(84, 84, 84) 100%);
}

.name-label {
  display: -webkit-box !important;
  display: -moz-box !important;
  display: -ms-box !important;
  display: box !important;
}

.card-title-price {
  margin: 20px auto;
  color: #7d838e;
}

.card-product-price {
  font-size: 20px;
  font-weight: 700;
  color: #272727
}

@media (max-width: 500px) {
  .card-label-cname {
    font-size: 18px;
    line-height: 15px;
  }
}

.card-label-cname {
  font-size: 20px;
  line-height: 15px;
}

.card-label-cname {
  padding: 5px 0;
}

.cb-container {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  border: 1px solid #ddd;
  border-radius: 7px;
  height: 95px;
  transition: margin .3s;
}

.margin-0 {
  margin-bottom: 0 !important
}

@media all and (max-width: 500px) {

  .cb-container,
  .fu-container {
    margin-bottom: 15px;
  }
}



.cb-container .image {
  width: 200px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
  /* flex-basis: 25%; */
}

.cb-container input {
  border: 1px solid #ddd;
  border-radius: 7px;
  flex-basis: 15%;
}

.cb-container .checkbox {
  flex-basis: 15%;
}

.cb-container .copy {
  font-size: 11px;
  flex-basis: 60%;
  font-weight: 500;
  margin: auto
}

.custom-design-header {
  font-size: 15px;
  text-align: center;
  font-weight: 700
}

.price {
  margin: 0 5px 5px 0;
  line-height: 1;
  align-self: flex-end;
  font-size: 12px;
  font-weight: 700;
}

.fu-container {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  border-radius: 7px;
  margin-top: 15px
}

.fu-container .file-upload {
  margin: auto
}

.fu-container {
  display: none;
  text-align: center
}

.digiTechHub_custom_design_upload_h {
  font-size: 11px;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 9999999
}

#cardTypeDiv input[type="file"] {
  display: none;
}

.custom-file-upload {
  display: inline-block;
  cursor: pointer;
  background-color: #fb6642 !important;
  border: 1px solid #fdb3a1 !important;
  color: #ececec !important;
  font-weight: bold;
  transition: transform .3s, border .3s, background .3s, box-shadow .3s, opacity .3s, color .3s;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}
#cardTypeDiv .cb-container .checkbox {
  flex-basis: 15%;
}

#cardTypeDiv .form-group.checkbox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0!important;
}

.css-1asb75j {
  height: 22px;
  min-width: 22px;
  line-height: 0;
  border-radius: 6px;
  cursor: default;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  white-space: nowrap;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  padding: 0px 8px;
  color: #212B36;
  font-size: 0.75rem;
  font-family: Public Sans,sans-serif;
  background-color: #DFE3E8;
  font-weight: 700;
  z-index: 9;
  top: 16px;
  right: 16px;
  position: absolute;
  text-transform: uppercase;
  background-color: #1890FF;
  color: white;
}