
.card-title {
  color: #10b981;
  font-size: 24px;
  font-family: Inter, sans-serif;
  font-weight: 200;
}
.p-3 {
  padding: 0.2rem 1rem;
}
.card-text {
  padding: 0rem 0rem 1rem 0rem;
  font-weight: 500;
  font-size: 18px;
  font-family: Inter, sans-serif;
}

.card {
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
  transition: all 0.2s;
  background-color: white;
  box-shadow: 5px 5px 5px 5px #e5e5e5;
  min-width: 100%;
  min-height: 253px;
}
.card:hover {
  transform:scale(1.03);
}

.services  {
  margin: 1rem 1rem 1rem 1rem;
}
ul li a {
  cursor: pointer;
}
.about {
  margin-bottom: 15rem;
}
.card-cover {
  /* padding: 2rem 0rem; */
  border-radius: 15px;
  height: auto;
  width: 100%;
}
.s {
  width: 100%;
  margin: 0 1rem!important;
}


@media (min-width: 640px){
.c9ud7 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
}

.c8scd {
    max-width: 100%;
}

.c8dbe {
  font-weight: 700;
  margin-top: 1rem;
}
.cpvc3 {
  display: flex;
  padding: 2rem 1rem;
}
@media (max-width: 768px){
.cpvc3 {
  display: block;
}
}
.homepageLabel{
  border-bottom: 2px solid #8BC34A;
  width: fit-content;
  margin: 20px auto;
}

.serviceCard{
    width: 90%;
    max-width: 375px;
    padding: 40px 20px;
    margin: auto;
    border-radius: 5px;
    -webkit-box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.233);
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.233);
    background-color: var(--white , #fff);
    display: inline-flex;
    margin: 10px;
    min-height: 350px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    transition: box-shadow 0.3s ease-in-out;
  }
.card__icon {
  width: 75px;
}
.card-texts {
  text-align: center;
}
.serviceCard:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.8);
}

.gradient-custom {
  background: #f6d365;
  background: linear-gradient(#f6d365,#fda085);
}

.hero_img,
.price_icon > img {
    display: block; /* Images are by default displayed inline - which causes the padding below the image. (because of line-height) */
    max-height: 20vh;
    min-width: 100%;
}

.content{
    display: flex;
    padding: 10% 12%;
    text-align: center;
    flex-direction: column;
    background-color: white;
    justify-content: space-around;
    gap: 1.5rem;
}

.text_content p{
    font-size: 1rem;
    color: var(--desaturate-blue);
    line-height: 1.6rem;
}

.text_content{
    display: flex;
    padding: 0 0.5rem;
    justify-content: space-between;
    flex-direction: column;
    gap: 1rem;
}

.price_container,
.price_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--very-pale-blue);
    
}

.price_container{
    padding: 7%;
}

.price_info{
    gap: 0.5rem;
}

.price_change > a {
    text-decoration: underline;
}

.price_box > p {
    font-size: 1.05rem;
    color: var(--desaturate-blue);
    padding-top: 7%;
}

.price_icon > img{
    max-width: 85%;
}

.paymentcontainer{
  display: flex;
  flex-direction: column;
  max-width: 90%;
  border-radius: 1.5rem;
  overflow: hidden;
  box-shadow: rgba(114, 128, 167, 0.09) 2px 2px 1px, rgba(114, 128, 167, 0.09) 2px 4px 2px, rgba(114, 128, 167, 0.09) 2px 8px 4px, rgba(114, 128, 167, 0.09) 2px 16px 8px, rgba(114, 128, 167, 0.09) 2px 32px 16px;
  margin-left: 5%;
}

@media  screen and (max-width: 576px){  
  .paymentcontainer{
      max-width: 310px;
      margin-left: 10%;
  }
  .paymentcontent{
      padding: 10% 7%;
  }

  .price_box > p {
      font-size: 0.8rem
  }



  .cancel_order{
      font-size: 1rem;
  }
}

table {
  border-color: #ececec;
  border-spacing: 0;
  margin-bottom: 1em;
  width: 100%;
  color: #777;
}

thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}

tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

.shop_table .order-total td, .shop_table .order-total th, .shop_table thead th {
  border-width: 3px;
}

.shop_table td, th {
  border-bottom: 1px solid #ececec;
  font-size: .9em;
  line-height: 1.3;
  padding: 0.5em;
  text-align: left;
}