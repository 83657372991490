.App {
  text-align: center;
  font-family: Inter, sans-serif;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d;
}

.alert-danger {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.pt-8 {
  padding-top: 2rem;
}

.justify-between {
  justify-content: space-between;
}
.items-start {
  align-items: flex-start;
}
.flex {
  display: flex;
}

.duration-200 {
  transition-duration: 200ms;
}

.text-gray-200 {
    --tw-text-opacity: 1;
    color: rgb(229 231 235 / var(--tw-text-opacity));
}

.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}
.w-full {
    width: 100%;
}
.mt-8 {
    margin-top: 2rem;
}

.border-2 {
  border-width: 2px;
}

.rounded {
  border-radius: 0.25rem;
}

.dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}
/* Style for the dialog content */
.dialog-content {
  /* background-color: #fff; */
  border-radius: 10px;
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); */
  text-align: center;
  position: relative;
  padding: 0 0;
}
/* Style for the close icon */
.close-icon {
  position: absolute;
  top: 5%;
  right: 0;
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  border: none;
  color: #333;
  cursor: pointer;
  padding: 0;
}

.popup-image {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media (min-width: 640px) {
  .dialog-content {
    max-width: 50%;
  }
}