.card-container {
	background-color: white;
	min-width: 350px;
	max-width: 600px;
	height: auto;
	border-radius: 14px;
	box-shadow: 0px 10px 30px hsl(185, 75%, 35%);
}

.theme4 header {
	background-image: url("bg-pattern-card.svg");
	background-position: 0px 0px;
	/* background-repeat: no-repeat; */
	background-size: contain;
	text-align: center;
	border-top-left-radius: 14px;
	border-top-right-radius: 14px;
}

.card-container img {
	margin: auto;
	width: 100px;
	border: solid white 4px;
	border-radius: 50%;
	margin-top: 75px;
}

.bold-text {
	font-weight: bold;
	font-size: 1.1rem;
	text-align: center;
	padding: 10px 20px 0px 20px;
}

.normal-text {
	font-weight: normal;
	font-size: 0.95rem;
	color: hsl(0, 0%, 50%);
	text-align: center;
	padding-bottom: 10px;
}

/* SOCIAL STATS */
.smaller-text {
	font-weight: normal;
	font-size: 0.7rem;
	color: hsl(0, 0%, 50%);
	text-align: center;
	letter-spacing: 1px;
	padding-bottom: 20px;
	line-height: 5px;
}

.social-container {
	display: grid;
	border-top: solid rgb(206, 206, 206) 1px;
	text-align: left;
	padding: 2px 20px;
	line-height: 2rem;
}

.followers {
	flex: 1;
}

.likes {
	flex: 1;
}

.photos {
	flex: 1;
}

@media screen and (max-width: 479px) {
	.theme4 .actionbar {
		top: 0;
		right: auto;
	}
}

.theme4 .actionbar {
	position: relative;
	left: auto;
	top: 0;
	right: -100px;
	bottom: 0%;
	display: flex;
	width: 200px;
	height: 40px;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	background-color: #3dcad3;
	font-family: 'Open Sans', sans-serif;
}