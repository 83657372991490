.--info-image {
  width: 317px;
  aspect-ratio: 1;
  border-radius: 8px 8px 0 0;
}

.Info h1 {
  text-align: center;
  font-size: 25px;
  margin: 1.5rem 0 0.3rem 0;
  line-height: 30px;
  line-height: 100%;
  font-weight: 700;
}

.Info h2 {
  text-align: center;
  color: #f3bf99;
  font-size: 12.8px;
  font-weight: 400;
  line-height: 15px;
  line-height: 100%;
}

.--info-portfolio {
  color: #ffffff;
  text-decoration: none;
}

.--info-portfolio:active {
  color: #ffffff;
}

.Info p {
  text-align: center;
  font-size: 10.24px;
  margin: 0.5rem 0;
}

.--info-buttons {
  display: flex;
  justify-content: space-around;
  padding: 1.2rem;
}

.Info button {
  width: 115px;
  height: 34px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  line-height: 16px;
}

.--info-email a {
  text-decoration: none;
  color: #374151;
}

.--info-email {
  background-color: #ffffff;
  color: #374151;
}

.--info-linkedin a {
  text-decoration: none;
  color: #ffffff;
}

.--info-linkedin {
  background-color: #5093e2;
  color: #ffffff;
}

.About {
  padding: 0.2rem 2rem 1rem;
  line-height: 30px;
  padding: 0.2rem 2rem 0rem 1rem;
}

.About h2 {
  font-size: 16px;
}

.About div {
  font-size: 14px;
}

.About a {
  font-size: 14px;
}

.Interests {
  padding: 0.2rem 2rem 2rem;
}

.Interests h2 {
  font-size: 16px;
}

.Interests p {
  font-size: 10.24px;
  line-height: 15px;
}

.Footer {
  background-color: #161619;
  border-radius: 0 0 8px 8px;
}

.Footer ul {
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding: 1rem 5rem;
}

.Footer ul > li {
  width: 25px;
  height: 25px;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Footer a {
  color: #918e9b;
}

.theme2 {
  margin: 1rem auto;
  max-width: 317px;
  background-color: #1a1b21;
  border-radius: 8px;
  color: white;
}